import React, { useState, useEffect, useRef } from "react";
import Board from "../board/Board";
import EndoraIntro from "../Intro/EndoraIntro";
import EndoraTitle from "../title/EndoraTitle";
import Settings from "../settings/Settings";
import { TILE_COUNT } from "../constants";
import muteIcon from "../images/muteIcon.svg";
import playIcon from "../images/playIcon.svg";

import playfulSong from "../songs/playfulSong.mp3";
import defaultImage from "../images/defaultImage.png";
import endoraLogo from "../images/endoraLogo.png";
import howToPlay from "../images/howToPlay.png";
import solvePuzzle from "../images/solvePuzzle.png";
import puzzleImage from "../images/puzzleImage.png";
import useMediaQuery from "use-mediaquery";
import closeIcon from "../images/closeIcon.png";

import s from "./app.module.scss";
function App() {
  const [imgUrl, setImgUrl] = useState("");
  const [option, setOption] = useState("title");
  const [start, setStart] = useState(true);
  const [tiles, setTiles] = useState([...Array(TILE_COUNT).keys()]);
  const [muted, setMuted] = useState(true);
  const [isStarted, setIsStarted] = useState(false);
  const [hasWon, setHasWon] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);

  const isMobile = useMediaQuery("(max-width: 800px)");

  const handleMute = () => {
    if (audioRef?.current && !audioRef?.current.paused) {
      setMuted(true);
      audioRef.current.pause();
    }
  };

  // useEffect(() => {
  //   window.addEventListener("blur", handleMute);

  //   return () => window.removeEventListener("blur", handleMute);
  // }, []);

  useEffect(() => {
    console.log(muted);
  }, [muted]);

  const audioRef = useRef();

  const imageFormatter = (src) => {
    //define the width to resize e.g 600px
    const resize_width = 250; //without px
    const resize_height = 250;

    //get the image selected
    var item = src;

    //create a FileReader
    var reader = new FileReader();

    //image turned to base64-encoded Data URI.
    reader.readAsDataURL(item);
    reader.name = item.name; //get the image's name
    reader.size = item.size; //get the image's size
    reader.onload = function (event) {
      var img = new Image(); //create a image
      img.src = event.target.result; //result is base64-encoded Data URI

      img.onload = function (el) {
        var elem = document.createElement("canvas"); //create a canvas

        var ctx = elem.getContext("2d");

        ctx.canvas.width = resize_width;
        ctx.canvas.height = resize_height;
        var imgSize = Math.min(img.width, img.height);
        // The following two lines yield a central based cropping.
        // They can both be amended to be 0, if you wish it to be
        // a left based cropped image.
        var left = (img.width - imgSize) / 2;
        var top = (img.height - imgSize) / 2;
        //var left = 0; // If you wish left based cropping instead.
        //var top = 0; // If you wish left based cropping instead.
        ctx.drawImage(
          img,
          left,
          top,
          imgSize,
          imgSize,
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.width
        );

        //get the base64-encoded Data URI from the resize image
        var srcEncoded = ctx.canvas.toDataURL("image/png", 1);

        setImgUrl(srcEncoded);

        setOption("game");
        /*Now you can send "srcEncoded" to the server and
              convert it to a png o jpg. Also can send
              "el.target.name" that is the file's name.*/
      };
    };
  };

  const readUrl = () => {
    return imageFormatter(document.querySelector("#uploader").files[0]);
  };

  const skip = () => {
    setImgUrl(defaultImage);

    setStart(true);
    setOption("game");
  };

  useEffect(() => {
    if (!muted) {
      if (audioRef?.current.paused) {
        setMuted(false);
        audioRef.current.play();
      }
    }
  }, [muted]);

  return (
    <>
      {" "}
      {showHowToPlay && (
        <div className={s.modal}>
          <div className={s.closeIcon}>
            <img src={closeIcon} onClick={() => setShowHowToPlay(false)} />
          </div>
          <div className={s.title}>
            <img src={howToPlay} />
            How to play Sliding Memories
          </div>
          <div className={s.content}>
            Endora presents a sneak peek of one of our FIRST puzzle games:
            Sliding Memories. <br></br>
            <br></br>Relax as you go back in time and play a classic childhood
            puzzle game while reflecting on a memory.<br></br>
            <br></br> Simply slide the jumbled pieces of the 3x3 grid around
            until you get the memory into its original form. <br></br>
            <br></br>How hard can it be? <br></br>
            <br></br>Best of luck! <br></br>
            <br></br>Hint: sometimes you have to think backwards in order to
            move forward.
          </div>
        </div>
      )}
      <div className={`${"App"} ${showHowToPlay && s.filter}`}>
        <audio
          ref={audioRef}
          src={playfulSong}
          autoPlay={muted}
          muted={muted}
          loop={true}
        />
        {(!isMobile ||
          (isMobile && option !== "game") ||
          (isMobile && option === "game" && hasWon)) && (
          <div className={`${s.desktopIcons} `}>
            <img
              style={{
                width: "50%",
                cursor: "pointer",
              }}
              src={muted ? muteIcon : playIcon}
              onClick={() => setMuted(!muted)}
            />

            <div style={{ fontFamily: "Work Sans" }}>
              Music: {muted ? "Off" : "On"}
            </div>
          </div>
        )}
        {option === "game" && !hasWon && (
          <div className={` ${s.mobile}`}>
            <img src={puzzleImage} />
          </div>
        )}
        {isStarted && !hasWon && option === "game" && (
          <>
            {" "}
            {!isMobile && (
              <div
                className={`${s.desktopIcons} ${s.nonMobile}`}
                style={{ top: "20%" }}
                onClick={() => setShowHowToPlay(true)}
              >
                <img
                  style={{
                    width: "40%",
                    cursor: "pointer",
                  }}
                  src={howToPlay}
                />
                <div style={{ fontFamily: "Work Sans" }}>How to Play</div>
              </div>
            )}
            <div
              className={`${s.desktopIcons} ${s.nonMobile}`}
              style={{ top: "35%" }}
              onClick={() => {
                setIsStarted(true);
                setHasWon(true);
              }}
            >
              <img
                style={{
                  width: "50%",
                  cursor: "pointer",
                }}
                src={solvePuzzle}
              />
              <div style={{ fontFamily: "Work Sans" }}>Solve For Me</div>
            </div>
          </>
        )}

        {option !== "title" && (
          <div className={s.sneakPeak}>
            <img src={endoraLogo} onClick={() => setOption("title")} />
            <div className={s.sneakPeakText}>Sneak Peek</div>
          </div>
        )}

        {isMobile && option === "game" && !hasWon && (
          <div className={s.mobileIcons}>
            <div
              onClick={() => setShowHowToPlay(true)}
              className={s.singleMobileIcon}
            >
              <img
                style={{
                  width: "40%",
                  cursor: "pointer",
                }}
                src={howToPlay}
              />
              <div style={{ fontFamily: "Work Sans" }}>How to Play</div>
            </div>
            <div
              className={s.singleMobileIcon}
              onClick={() => {
                setIsStarted(true);
                setHasWon(true);
              }}
            >
              <img
                style={{
                  width: "50%",
                  cursor: "pointer",
                }}
                src={solvePuzzle}
              />
              <div style={{ fontFamily: "Work Sans" }}>Solve For Me</div>
            </div>
            <div className={s.singleMobileIcon}>
              <img
                style={{
                  width: "50%",
                  cursor: "pointer",
                }}
                src={muted ? muteIcon : playIcon}
                onClick={() => setMuted(!muted)}
              />
              <div style={{ fontFamily: "Work Sans" }}>
                Music: {muted ? "Off" : "On"}
              </div>
            </div>
          </div>
        )}
        {option === "title" ? (
          <>
            <EndoraTitle setOption={setOption} />
          </>
        ) : option === "intro" ? (
          <>
            <EndoraIntro setOption={setOption} readUrl={readUrl} skip={skip} />
          </>
        ) : option === "game" ? (
          <>
            <Board
              imgUrl={imgUrl}
              setOption={setOption}
              start={start}
              setTiles={setTiles}
              tiles={tiles}
              isStarted={isStarted}
              setIsStarted={setIsStarted}
              hasWon={hasWon}
              setHasWon={setHasWon}
            />
          </>
        ) : option === "settings" ? (
          <>
            <Settings setOption={setOption} setStart={setStart} tiles={tiles} />
          </>
        ) : null}
      </div>
    </>
  );
}

export default App;
