import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/sneakpeek" />} />

        <Route path="/sneakpeek" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
