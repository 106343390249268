import React from "react";
import s from "./settings.module.scss";

const Settings = ({ setOption, setStart }) => {
  return (
    <div>
      <div
        className={s.text}
        onClick={() => {
          setOption("game");
          setStart(true);
        }}
      >
        Restart
      </div>
      <div className={s.text} onClick={() => setOption("intro")}>
        Select new game
      </div>
      <div className={s.text}>Share this game (TBD)</div>

      <button
        className={s.return}
        onClick={() => {
          setOption("game");
          setStart(false);
        }}
      >
        Return To Game
      </button>
    </div>
  );
};

export default Settings;
