import React from "react";
import s from "./intro.module.scss";
import puzzleImage from "../images/puzzleImage.png";

const EndoraIntro = ({ setOption, readUrl, skip }) => {
  return (
    <div className={s.container}>
      <div className={s.title}>Sliding Memories</div>
      <img src={puzzleImage} className={s.image} />
      <div className={s.text}>
        Personalize this game with your memories! Upload a treasured moment with
        your besties, a beloved family photo, or your fluffy family members...
      </div>

      <div className={s.buttonsContainer}>
        <div onClick={skip} className={s.skip}>
          Skip This Step
        </div>
        <label class={s.customUpload}>
          <input type="file" id="uploader" onChange={readUrl} />
          Upload Photo
        </label>
      </div>
    </div>
  );
};

export default EndoraIntro;
