import React, { useEffect, useState } from "react";
import Tile from "../Tile";
import { GRID_SIZE, BOARD_SIZE } from "../constants";
import { canSwap, shuffle, swap, isSolved } from "../helpers";
import s from "./board.module.scss";
import puzzleImage from "../images/puzzleImage.png";
import useMediaQuery from "use-mediaquery";
import Odometer from "react-odometerjs";
import TimerPic from "../images/timer.png";
import share from "../images/share.png";

function Board({
  imgUrl,
  setOption,
  start,
  tiles,
  setTiles,
  isStarted,
  setIsStarted,
  hasWon,
  setHasWon,
}) {
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [timer, setTimer] = useState(0);
  const isMobile = useMediaQuery("(max-width: 800px)");

  const [deviceWidth, setDeviceWith] = useState(() => window.innerWidth);
  const [scale, setScale] = useState(false);
  const [size, setSize] = useState(() =>
    isMobile ? deviceWidth - 45 : BOARD_SIZE
  );

  useEffect(() => {
    setSize(() => (isMobile ? deviceWidth - 45 : BOARD_SIZE));
  }, [isMobile]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    if (hasWon) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [hasWon]);

  const formatTime = () => {
    if (timer < 10) {
      return `00:0${timer}`;
    }
    if (timer >= 10 && timer < 60) {
      return `00:${timer}`;
    }
    if (!(timer % 60)) {
      const minutes = timer / 60;
      const processed = minutes <= 9 ? `0${minutes}` : minutes;

      return `${processed}:00`;
    }
    if (timer > 60) {
      const minutes = Math.floor(timer / 60);
      const seconds = Math.floor(timer % 60);
      const processedMinute = minutes <= 9 ? `0${minutes}` : minutes;
      const processedSeconds = seconds <= 9 ? `0${seconds}` : seconds;
      return `${processedMinute}:${processedSeconds}`;
    } else {
      return "NO TIME";
    }
  };

  const shuffleTiles = () => {
    const shuffledTiles = shuffle(tiles);
    setTiles(shuffledTiles);
  };

  const swapTiles = (tileIndex) => {
    if (canSwap(tileIndex, tiles.indexOf(tiles.length - 1))) {
      const swappedTiles = swap(
        tiles,
        tileIndex,
        tiles.indexOf(tiles.length - 1)
      );
      setTiles(swappedTiles);
      setMoves((prev) => prev + 1);
    }
  };

  const handleTileClick = (index) => {
    swapTiles(index);
  };

  const handleStartClick = () => {
    shuffleTiles();
    setIsStarted(true);
  };

  useEffect(() => {
    if (isSolved(tiles) && isStarted) {
      setTimeout(() => {
        setScale(true);

        setTimeout(() => {
          setScale(false);
          setTimeout(() => {
            setHasWon(true);
          }, 2000);
        }, 2000);
      }, 1000);
    }
  }, [tiles]);

  const handleShare = async () => {
    const text = `Check out Endora, this fun game filled with memories! I remade a memory in ${formatTime()} and ${moves} moves: https://www.endora.app`;
    if (isMobile) {
      navigator
        .share({
          text,
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => console.log("Sharing failed", error));
    } else {
      await navigator.clipboard.writeText(text);

      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
  };

  const pieceWidth = Math.round(
    (isMobile ? deviceWidth - 45 : BOARD_SIZE) / GRID_SIZE
  );
  const pieceHeight = Math.round(
    (isMobile ? deviceWidth - 45 : BOARD_SIZE) / GRID_SIZE
  );
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    margin: "auto",
  };
  //const hasWon = isSolved(tiles)

  useEffect(() => {
    if (start) {
      handleStartClick();
    }
  }, [imgUrl]);

  useEffect(() => {
    if (hasWon) {
      setSize(() => (isMobile ? deviceWidth : 350));
    }
  }, [hasWon]);

  return (
    <>
      {!hasWon ? (
        <>
          <div className={s.logo}>
            <img src={puzzleImage} />
          </div>
          <div className={s.desktopMoves}>
            <div
              style={{
                fontFamily: "Work Sans",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ marginRight: "0.5rem", width: "25%" }}
                src={TimerPic}
              />{" "}
              {formatTime()}
            </div>
            <div className="odometer">
              {/* Moves:{" "}
              <span className="odometer" id="odometer">
                {moves}
              </span> */}
              <span
                style={{
                  marginRight: "0.5rem",
                  fontFamily: "Work Sans",
                  fontWeight: "600",
                }}
              >
                Moves:
              </span>
              <Odometer value={moves} format="(.ddd)" theme="default" />
            </div>
          </div>
          <div
            style={{
              marginTop: isMobile ? "-10rem" : "-20rem",
              width: isMobile ? `${deviceWidth - 15}px` : "400px",
              height: isMobile ? `${deviceWidth - 15}px` : "400px",
              display: "flex",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.3)",
              borderRadius: "16px",
              // marginLeft: isMobile ? `15px` : "",
              // marginRight: isMobile ? `15px` : "",
            }}
          >
            {" "}
            <ul
              style={style}
              className={`board ${s.desktopBoard} ${scale && s.scale}`}
            >
              {tiles.map((tile, index) => (
                <Tile
                  key={tile}
                  index={index}
                  imgUrl={imgUrl}
                  tile={tile}
                  width={pieceWidth}
                  height={pieceHeight}
                  handleTileClick={handleTileClick}
                />
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className={s.wonContainer}>
            <div
              style={{
                width: isMobile ? `${deviceWidth - 100}px` : "400px",
                height: isMobile ? `${deviceWidth - 100}px` : "400px",
                display: "flex",
                alignItems: "center",
                margin: "auto",
                marginTop: isMobile ? "1rem" : "",
                justifyContent: "center",
                background: "rgba(255, 255, 255, 0.3)",
                borderRadius: "16px",
                padding: isMobile && "15px 0px",
              }}
            >
              <div
                className={s.desktopWon}
                style={{
                  backgroundImage: `url(${imgUrl})`,
                  backgroundSize: "100%",
                  margin: "auto",

                  width: isMobile ? `${deviceWidth - 150}px` : "350px",
                  height: isMobile ? `${deviceWidth - 150}px` : "350px",
                }}
              ></div>
            </div>

            <div className={s.statsContainer}>
              <div className={s.title}>Bravo! Here are your stats: </div>

              <div className={s.wonMoves}>
                <div
                  style={{
                    fontFamily: "Work Sans",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ marginRight: "0.5rem", width: "25%" }}
                    src={TimerPic}
                  />{" "}
                  {formatTime()}
                </div>
                <div className="odometer">
                  {/* Moves:{" "}
              <span className="odometer" id="odometer">
                {moves}
              </span> */}
                  <span
                    style={{
                      marginRight: "0.5rem",
                      fontFamily: "Work Sans",
                      fontWeight: "600",
                    }}
                  >
                    Moves:
                  </span>
                  <Odometer value={moves} format="(.ddd)" theme="default" />
                </div>
              </div>

              <button
                className={s.primary}
                style={{ zIndex: showModal ? "1" : "3" }}
                onClick={handleShare}
              >
                <img src={share} /> Share
              </button>
              <div className={s.playAgain}>
                <br></br>
                <span
                  onClick={() => {
                    setHasWon(false);
                    setOption("intro");
                  }}
                >
                  ...or play again
                </span>
              </div>
            </div>
          </div>
          <div className={s.signUp}>
            <div>
              To play with more memories and try other games, sign up for early
              access!
            </div>
            <a href="https://www.endora.app/#earlyaccess">
              {" "}
              <button className={s.primary}>Sign Up Today</button>
            </a>
          </div>
        </>
      )}

      {hasWon && isStarted && (
        <>
          <div
            style={{
              position: "absolute",
              top: "50%",
              width: "30%",
              padding: "1rem",
              borderRadius: "80px",
              fontFamily: "Work Sans",
              zIndex: 2,
              textAlign: "center",
              background: "white",
              color: "black",
              transition: "opacity 2s",

              opacity: showModal ? 1 : 0,
            }}
          >
            Results copied to clipboard
          </div>
        </>
      )}
    </>
  );
}

// <div
//   className={`${s.buttonsContainer} ${s.biggerContainer}`}
// >
//   <a href="https://www.endora.app/#earlyaccess">
//     <button className={s.secondary}>
//       {" "}
//       Join the Demo
//     </button>
//   </a>
// </div>;
export default Board;
