import React from "react";
import StartImage from "../images/startImage.png";
import s from "./title.module.scss";

const EndoraTitle = ({ setOption }) => {
  return (
    <div className={s.container}>
      <img className={s.image} src={StartImage} />
      <div className={s.buttonsContainer}>
        <button onClick={() => setOption("intro")} className={s.button}>
          Play
        </button>
      </div>
    </div>
  );
};

export default EndoraTitle;
